import React from 'react';
import styled from 'styled-components';
import { colors } from 'js-components';
import { PageRegular, PageSmall } from '../PageTitle';

import Q from './logos/managedByQ.svg';
import Signpost from './logos/signpost.svg';
import Zocdoc from './logos/zocdoc.svg';
import Wework from './logos/wework.svg';
import Uber from './logos/uber.svg';
import Wix from './logos/wix.svg';


const getLogo = logo => {
  switch (logo) {
    default:
    case 'q':
      return Q;
    case 'zocdoc':
      return Zocdoc;
    case 'signpost':
      return Signpost;
    case 'uber':
      return Uber;
    case 'wix':
      return Wix;
    case 'wework':
      return Wework;
  }
};

function Background(props) {
  return (
    <QuoteCard>
      <img alt="logo" src={getLogo(props.logo)} />
    </QuoteCard>
  );
}

Background.defaultProps = {
  gradientType: 'light',
  backgroundSize: 'fill',
  backgroundRepeat: 'none',
  caption: 'Watch The Video',
  videoLink: 'https://player.vimeo.com/video/264697195?autoplay=1',
  background: 'hero',
  opacity: 1
};

export default Background;

const QuoteCard = styled.div`
  border-radius: 8pt;
  width: 190px;
  height: 130px;
  background-color: ${colors.white};
  overflow: hidden;
  box-shadow: 0pt 4pt 32pt rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: 4pt;
  text-align: left;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    padding-bottom: 16px;
  }
`;

const TextWrapper = styled.div`
  margin: 0 18pt;
`;

const Quote = styled(PageRegular)`
  height: 120pt;
  font-style: italic;

  @media (max-width: 1024px) {
    height: auto;
    padding-bottom: 16px;
  }
`;
