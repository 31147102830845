import React from 'react';
import styled from 'styled-components';
import CustomerCard from './CustomerCard';

function CustomerCardGroup() {
  return (
    <DeckWrapper>
      <Deck>
        <div>
          <CustomerCard
            quote="Happy reps make more calls. Our reps are happy, our managers are happy, numbers are up."
            name="Evan Bartlett"
            position="Head of Inside Sales"
            logo="zocdoc"
          />
        </div>
        <div>
          <CustomerCard
            quote="Truly is the Tesla of phone systems.  Everyone first says `do I need that?` then they get in one and realize they do."
            name="Matt Matsuda"
            position="Head of IT"
            logo="signpost"
          />
        </div>
        <div>
          <CustomerCard
            quote="Easy to implement, easy to onboard, easy to administer. I recommend Truly all the time to my peers."
            name="Michael Muse"
            position="Head of Sales Operations"
            logo="wework"
          />
        </div>
        <div>
          <CustomerCard
            quote="It's not one thing.  It's a hundred little things they get right."
            name="Chalie Bergevin"
            position="Sales Lead"
            logo="uber"
          />
        </div>
        <div>
          <CustomerCard
            quote="Of the 99 things that break daily in the sales stack, Truly is never one of them."
            name="Melanie Terranova"
            position="Head of Revenue Ops"
            logo="wix"
          />
        </div>
      </Deck>
    </DeckWrapper>
  );
}

export default CustomerCardGroup;

const DeckWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const Deck = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 0px 20px 0px 20px;
  flex-direction: row;
  max-width: 1400px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
